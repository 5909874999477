export default {
    computed: {
        headers() {
            return [
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'promotionType',
                    text: this.$t('labels.type'),
                    type: 'string',
                },
                {
                    value: 'promotionTarget',
                    text: this.$t('labels.target'),
                    sortable: false,
                },
                {
                    value: 'discountType',
                    text: this.$t('labels.discount_type'),
                    type: 'string',
                },
                {
                    value: 'discountValue',
                    text: this.$t('labels.discount'),
                    type: 'number',
                },
            ]
        },
    },
}
