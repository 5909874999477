export default {
    computed: {
        headers() {
            return [
                {
                    value: 'name',
                    text: this.$t('coupon.name'),
                    type: 'string',
                },
                {
                    value: 'code',
                    text: this.$t('coupon.type'),
                    type: 'string',
                },
                {
                    value: 'value',
                    text: this.$t('coupon.value'),
                    sortable: false,
                    align: 'right',
                    width: '160px',
                },
                {
                    value: 'appliesToDelivery',
                    text: this.$t('coupon.applies_to_delivery'),
                    type: 'string',
                    align: 'center',
                    width: '100px',
                },
                {
                    value: 'usageLimit',
                    text: this.$t('coupon.usage_limit'),
                    type: 'number',
                    align: 'right',
                    width: '100px',
                },
                {
                    value: 'userUsageLimit',
                    text: this.$t('coupon.user_usage_limit'),
                    type: 'number',
                    align: 'right',
                },
                {
                    value: 'restaurant',
                    text: this.$t('coupon.restaurants'),
                    type: 'number',
                },
            ]
        },
    },
}
