<template>
    <div>
        <page-title>{{ $t('coupon.list_page_title') }}</page-title>
        <coupon-table>
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.COUPON_CREATE"
                ></create-button>
            </template>
        </coupon-table>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PageTitle from '@/components/layout/components/pageTitle'
import CouponTable from '@/components/pages/coupons/Table'
import CreateButton from '@/components/form/Actions/CreateButton'

export default {
    components: {
        CreateButton,
        PageTitle,
        CouponTable,
    },
    data() {
        return {
            routeType,
        }
    },
}
</script>
