<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filter
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    add-id-filter
                ></table-filter>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :headers="headers"
                    :items="items"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- promotion type -->
                    <template v-slot:[`item.promotionType`]="{ item }">
                        {{ $t('promotion_type.' + item.promotionType) }}
                    </template>

                    <!-- promotion target column -->
                    <template v-slot:[`item.promotionTarget`]="{ item }">
                        <span v-if="item.promotionType === promotionType.user">
                            <v-chip
                                v-for="(user, index) in item.users.slice(0, 3)"
                                :key="`promotion-${index}`"
                                color="blue lighten-5"
                            >
                                {{ user.email }}
                            </v-chip>
                            <span v-if="item.users.length > 3"> ... </span>
                        </span>
                        <span
                            v-if="item.promotionType === promotionType.company"
                        >
                            <v-chip color="light-green lighten-5">
                                {{ item.company.name }}
                            </v-chip>
                        </span>
                        <span
                            v-if="
                                item.promotionType === promotionType.allowance
                            "
                        >
                            <v-chip
                                v-for="(allowance,
                                index) in item.allowances.slice(0, 3)"
                                :key="`allowance-${index}`"
                                color="amber lighten-5"
                            >
                                {{ allowance.name }}
                            </v-chip>
                            <span v-if="item.allowances.length > 3"> ... </span>
                        </span>
                    </template>

                    <template v-slot:[`item.discountType`]="{ item }">
                        {{ $t('promotion_discount_type.' + item.discountType) }}
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button
                            @execute="
                                $router.push({
                                    name: routeType.PROMOTION_EDIT,
                                    params: { id: item.id },
                                })
                            "
                        ></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    strong
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import promotionType from '@/enum/promotionType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/promotions/mixins/Headers'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    data() {
        return {
            api: RestApiType.PROMOTIONS,
            promotionType,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.promotions')
        },
    },
}
</script>
